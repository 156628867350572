import key_mirrors from 'key-mirror';

export default key_mirrors({
    SET_USER: null,
    SET_INDUSTRY: null,
    SET_PROVINCE: null,
    SET_CITY: null,
    TOPBAR: null,
});
// const SET_USER = 'SET_USER';
// const SET_INDUSTRY = 'SET_INDUSTRY';
// const SET_PROVINCE = 'SET_PROVINCE';
// const SET_CITY = 'SET_CITY';
//
// export default {
//     SET_USER,
//     SET_INDUSTRY,
//     SET_PROVINCE,
//     SET_CITY,
//
// }
