import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
const app = createApp(App)
import 'default-passive-events'

import 'element-plus/dist/index.css'
import {
	ElPagination,
	ElRow,
	ElCol,
	ElPopover,
	ElButton,
	ElDialog,
	ElTabs,
	ElForm,
	ElRadio,
	ElCheckbox,
	ElTable,
	ElDatePicker,
	ElCollapse,
	ElPageHeader,
	ElIcon,
	ElTabPane,
} from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

// app.use(ElementPlus)

app.use(ElPagination)
app.use(ElRow)
app.use(ElCol)
app.use(ElPopover)
app.use(ElButton)
app.use(ElDialog)
app.use(ElTabs)
app.use(ElForm)
app.use(ElRadio)
app.use(ElCheckbox)
app.use(ElTable)
app.use(ElTabPane)
app.use(ElDatePicker)
app.use(ElCollapse)
app.use(ElPageHeader)
app.use(ElIcon)
app.use(zhCn );



//vantUI
import {
	Button,
	Icon,
	Search,
	Swipe,
	SwipeItem,
	Tabbar,
	TabbarItem,
	NavBar,
	Grid,
	GridItem,
	List,
	Cell, CellGroup,
	Col, Row,
	DropdownMenu, DropdownItem,
	ActionSheet ,SubmitBar,
	Tab, Tabs,Empty,
	Form, Field, Uploader ,Picker ,Popup ,Cascader ,
	Circle , Lazyload ,PullRefresh ,DatePicker,
	Stepper ,Checkbox, CheckboxGroup, NumberKeyboard 
} from 'vant'; 

app.use(Button);
app.use(Icon);
app.use(Search);
app.use(Swipe);
app.use(SwipeItem);
app.use(Tabbar);
app.use(TabbarItem);
app.use(NavBar);
app.use(Grid);
app.use(GridItem);
app.use(List);
app.use(Cell);
app.use(CellGroup);
app.use(Col);
app.use(Row);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(ActionSheet);
app.use(SubmitBar);
app.use(Tab);
app.use(Tabs);
app.use(Empty);
app.use(Form);
app.use(Field);
app.use(Uploader);
app.use(Picker);
app.use(Popup);
app.use(Cascader );
app.use(Circle );
app.use(Lazyload );
app.use(PullRefresh ); 
app.use(DatePicker );
app.use(Stepper );
app.use(Checkbox );
app.use(CheckboxGroup );
app.use(NumberKeyboard );




import '@/styles/main.scss';
import '@/styles/subassembly.scss';
import '@/styles/app.scss';

// const app = createApp(App)
app.config.productionTip = false;



app.use(router)
app.use(store)
app.mount('#app')
