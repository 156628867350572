import constants from '../constants';

const state = {
    list: []

};

const mutations = {
    [constants.SET_INDUSTRY] (state, list) {
        // // //console.log("state: "+JSON.stringify(state))
        // // //console.log("list: "+JSON.stringify(list))

        state.list = list;

    }
};

export default {
    state,
    mutations,
};
