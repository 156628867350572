<style lang="less">
	.app01{
		min-width: 1000px;
	}
	.app02{
		min-width: 250px;
		box-sizing: border-box;
		padding-top:90px;
	}
	.advisorMbg{
		background: #ccc;
	}
	
</style>
<template>
  <div id="app" :class="getAppClass">

    <router-view />
    <Footer v-if="!advisorMExist&&!testMExist"></Footer>

  </div>
</template>

<script> 
import { ElMessage} from 'element-plus';
  import HelloWorld from "@/components/HelloWorld.vue";
  import Footer from "@/components/desktop/layout/footer";
  import {set_user, set_industry, set_province, set_city} from './store/actions/index.js';
  // import {set_user, set_industry, set_province, set_city} from 'vuexx/actions';
  import store from './store/index';
  import {get_my_info,wexin_login} from "./api/user";
  import {check_login} from "./api/login";
  import cookie from "js-cookie";
  import constants from "./store/constants";
import { useRouter } from "vue-router";
  export default {
    name: "app",
    data() {
      return {
		admin: null,
		mobile: false,
		advisorMExist:false,
		testMExist:false,
		code:'',
		isLogin:false,
		isWexin:false,
		role:0,
      };
    },
	created(){
		//pc或手机页面宽度检测
		let width = document.documentElement.clientWidth;
		//console.log('width: '+width)
		if(width<600){
			this.mobile = true
		}

		let wx= window.navigator.userAgent.toLowerCase();
		if (wx.match(/MicroMessenger/i) == 'micromessenger'){
			this.isWexin = true;
		}else{
			this.isWexin = false;
			
		}

		let tsetstring = "http://www.hrllzp.com/adivisorM/home?code=904834258-9095fhu78feg&state=123"
		let tsetstring2 = tsetstring.substring(0,tsetstring.indexOf("?code="));
		console.log("indexOf: "+tsetstring.indexOf("?code="));
		console.log("tsetstring2: "+tsetstring2);

		console.log('isWexin: '+this.isWexin)
		// alert('isWexin: '+this.isWexin);
		this.isLogin = cookie.get("token") !== undefined;
		if(!this.isLogin){
			// let mob2 = this.isMobile();
			if(this.isWexin){
				// alert(window.location.href)
				let code= this.getUrlKey("code");//获取url参数code 
				if(code){ //拿到code，code传递给后台接⼝换取openid
					this.code = code;
					wexin_login(this.code,this.role).then((data)=>{
						this.code = "";
						if(data == "grant"){
							console.log('isWexin: '+this.isWexin)
							setTimeout(()=>{
								this.getCodeApiGrant("123",0);
							},300)
							
						}
						// if(data == "subscribe"){
						// 	ElMessage({type:'warning',grouping:true,message:'请关注公众号方便后续操作'});
						// 	setTimeout(()=>{
						// 		this.getSubscribe();
						// 	},200)
						// }
						cookie.set('token', data.token);//, {expires: 30}
                        cookie.set('user_id', data.id);

                        set_user().then( data => {
                            // //console.log('app set_user: '+data);
                            if(data != undefined) {
                                cookie.set('user_id', data.id);
                            }
                        });
						location.reload();

						



						// console.log('isWexin: '+data);
						
						// alert('isWexin: '+data);
					})
					
				}else{ 
					this.getCodeApi("123",0);
				}
			}
		}
		this.getInfo();//检测是否关注公众号
		// set_industry();
      // set_province();
      // set_city();
      // if(store.state.user.id === ''){
        set_user().then( data => {
          // // //console.log('app set_user: '+data);
          if(data != undefined) {
            cookie.set('user_id', data.id);
          }
          // // //console.log('store.state.user:')
          // // //console.log(JSON.stringify(store.state.user))
        });
      // }


      set_industry().then(() =>{
        // // //console.log("set_industry: "+JSON.stringify(store.state.industry))
      });
      set_province();
      set_city();
		
	},
    mounted() {
		//pc或手机页面宽度检测
		let width = document.documentElement.clientWidth;
		//console.log('width: '+width)
		if(width<600){
			this.mobile = true
		}
		//一对一移动模块特殊显示
		let path = window.location.href;
		if(path.indexOf('advisorM') != -1){
			this.advisorMExist = true;
		}else{
			this.advisorMExist = false;
		}
		if(path.indexOf('testM') != -1){
			this.testMExist = true;
		}else if(path.indexOf('testN') != -1){
			this.testMExist = true;
		}else{
			this.testMExist = false;
		}

		

      


    },
    vuex: {
      actions: {
        set_user,
        set_industry,
        set_province,
        set_city,
      }
    },
    // store,
    computed:{

    },
    methods: {
		getInfo() {
            get_my_info().then((data) => {
                // console.log("data: " + JSON.stringify(data));
                if (data != undefined) {
					if(data.subscribe=="0"&&data.openid!=""&&this.isWexin){
							ElMessage({type:'warning',grouping:true,message:'请关注公众号体验以便接收重要提醒'});
							setTimeout(()=>{
								this.getSubscribe();
							},1000)
					}
                }
            });
        },
		getSubscribe(){
			let url = "https://mp.weixin.qq.com/s?__biz=MzU0NjE3NDU4Mg==&mid=2247483847&idx=1&sn=0319d08de7c3851f63ddd9c796c9002f&chksm=fb60e2decc176bc817943857289803ac1a6b6bfac7d3e7de27ccfb530124b0c67c3bc1799868&token=185961016&lang=zh_CN#rd";
			window.location.replace(url);
		},
		
		getCodeApi(state,type){//获取code  
			let urlherf = window.location.href;
			if(urlherf.indexOf("code="!=-1)){
				urlherf = urlherf.replace(/(\?|#)[^'"]*/, '');  //去除参数
			}
			let urlNow=encodeURIComponent(urlherf);
			let scope='snsapi_base'; //只获取openid授权⽤户⽆感知//
			// let scope='snsapi_userinfo'; //获取完整用户信息 //
			//静默授权⽤户⽆感知
			let appid='wxda6d5ff54cae5453'; 
			let url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
			window.location.replace(url);
		} ,
		getCodeApiGrant(state,type){//获取code  
			let urlherf = window.location.href;
			if(urlherf.indexOf("code="!=-1)){
				urlherf = urlherf.replace(/(\?|#)[^'"]*/, '');  //去除参数
			}
			let urlNow=encodeURIComponent(urlherf);
			// let scope='snsapi_base'; //只获取openid授权⽤户⽆感知//
			let scope='snsapi_userinfo'; //获取完整用户信息 //
			//静默授权⽤户⽆感知
			let appid='wxda6d5ff54cae5453'; 
			let url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
			window.location.replace(url);
		} ,
		getUrlKey(name){//获取url 参数 
			return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
		},
		//是否移动端
		isMobile (){
        	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		},
		//不同功能界面初始样式
		getAppClass(){
			let classs = ''; 
			if(this.advisorMExist){  //一对一移动界面
				classs = 'advisorMbg';
			}else if(this.mobile && !this.advisorMExist){
				classs = 'app02';
			}else if(!this.mobile && !this.advisorMExist){
				classs = 'app01';
			}
			return classs;
		}
    },
    components: {
      Footer
    }
  };
</script>

<style>
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
