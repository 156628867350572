// const paths = "http://localhost:8080/llzp/";
const paths2 = "http://www.hrllzp.com:3141/llzp/";
const paths = "https://www.hrllzp.com:8443/llzp/";



function serialize(obj, prefix) {
    const str = [];
    for(const p in obj) {
        const value = obj[p];
        if (Object.prototype.hasOwnProperty.call(obj,p) && (value !== null && value !== undefined)) {
            const k = prefix ? prefix + "[" + p + "]" : p;
            str.push(typeof value == "object" ?
            serialize(value, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(value));
        }
    }
    return str.join("&");
}


const credentials = 'include';//process.env.NODE_ENV === 'production' ? 'same-origin' : 'include';
const mode = 'cors';//process.env.MODE_ENV === 'production' ? 'basic' : 'no-cors';


export function get(url, data = {}) {
    return fetch(paths+url + '?' + serialize(data), {
        headers: {
            'Accept': 'application/json',
		
        },
        method: 'GET',
        mode,
        credentials
    });
}

const mode1 = 'no-cors'
export function firstGet(url, data = {}) {
    return fetch(url + '?' + serialize(data), {
		credentials,
		method: 'GET',
        headers: {
            'Accept': 'application/json',			
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',			
        },
        mode: "no-cors",
      	cache: "force-cache",
        
    });
}



export function post(url, data = {}){
    // // window.//console.log("data: "+JSON.stringify(data));
    const params=new URLSearchParams();
    return fetch(paths+url, {
        headers: {
            'Accept': 'application/json, text/javascript, */*',
            'Content-Type': 'application/x-www-form-urlencoded',

        },
        method: 'POST',
        body: serialize(data),
        mode,
        credentials
    });




}



export function form(url, data){
    const formData = new FormData();
    Object.keys(data).map(function (key) {
        const value = data[key];
        if(value !== null && value !== undefined){
            formData.append(key, value);
        }
    });
    return fetch(paths+url, {
        method: 'POST',
        body: formData,
        mode,
        credentials
    });
}



export function formData(url, formData){
    return fetch(paths+url, {
        method: 'POST',
        body: formData,
        mode,
        credentials
    });
}

export default {
    get,
	firstGet,
    post,
    form,
    formData
};
