import cookie from 'js-cookie';
import server from '../server/resource';
import {ui_config} from '../config/api';

export function check_login(config = ui_config){
    return server.get('check_login',{},config);
}

// export function login(){
//     return formData('login');
// }

export function login_name(name,password,role,platform_key){
    return server.post('login', {name,password,role,platform_key});
}

// export function login_name(name,password,platform_key){
//     return server.post('login', {name,password,platform_key});
// }

export function logout(config = ui_config){
    //cookie.remove('token');
    return server.post('logout', {}, config);
}
export function userExist(login_name,role){
    return server.get('userExist', {login_name,role});
}

export function register(nickname,email,phone,password,role){
    return server.post('register', {nickname,email,phone,password,role});
}

export function send_check_mail(email,nickname,password,role){
    return server.post('send_check_mail', {email,nickname,password,role});
}

export function valid_bind_mail(code){
    return server.get('valid_bind_mail', {code});
}

export function send_password_mail(email,role){
    return server.post('send_password_mail', {email,role});
}

export function valid_password_mail(code){
    return server.post('valid_password_mail', {code});
}

export function reset_my_password(code, password) {
    return server.post('reset_my_password',{code, password});
}
