import cookie from 'js-cookie';
import server from '../server/resource';
// import post_form from 'modules/post_form'
// import {ui_config} from 'config/api';

// let ui_config = {loading: true, error: true};

export function upload_image(image){
    return server.form('uploadImg', {eImag: image});
}


//指定文件夹上传单张图片
export function upload_image_folder(image,floder){
    return server.form('uploadImgFolder', {eImag: image,floder});
}
//指定文件夹删除单张图片（包括缩略图一起删除）
export function delete_image_folder(delete_floder,delete_name){
    return server.form('deleteImgFolder', {delete_floder,delete_name});
}


export function upload_file(filename){
    return server.form('uploadFile', {file: filename});
}

export function upload_files(files){
    var form_data = new FormData();
    for(var i=0;i < files.length;i++) {
        var file = files[i];
        // //console.log(file);
        form_data.append('files',file);
    }
    return server.form_data('uploadFiles', form_data);
}

export function get_province_list() {
    return server.get('get_province_list', {});
}

export function get_city_list(province_id) {
    return server.get('get_city_list', {province_id});
}

export function get_industry_list() {
    return server.get('get_industry_list', {});
}

export function get_job_list(trade_id) {
    return server.get('get_job_list', {trade_id});
}

export function get_trade_list() {
    return server.get('get_trade_list', {});
}


//根据图片url返回图片所在的父文件夹和文件名
export function analysis_image_url(src){
    let arr = [];
    arr = src.split("/");
    let delete_floder = arr[arr.length-2];
    let delete_name = arr[arr.length-1];
    let result = {delete_floder:delete_floder,delete_name:delete_name};
    return result;
}

export function input_word_data(value,stroke_number) {
    return server.post('input_word_data', {value,stroke_number});
}
export function input_name_personality(surname,name) {
    return server.post('input_name_personality', {surname,name});
}
export function input_doushu(item,num1,num2,num3) {
    return server.post('input_doushu', {item,num1,num2,num3});
}
export function input_xiaoliu(item,key,use_key) {
    return server.post('input_xiaoliu', {item,key,use_key});
}
export function get_yinyuan_questions(gender) {
    return server.post('get_yinyuan_questions', {gender});
}
export function get_yinyuan(str,gender) {
    return server.post('get_yinyuan', {str,gender});
}



export function test_regexp(regexp,text) {
    return server.post('test_regexp', {regexp,text});
}
