<style lang="less">
	.footer{
		background: #333;
		padding: 0 10px 20px 10px;
		.con{
			overflow: hidden;
			.about{
				>div:first-child{
					font-family: PingFangSC-Regular;color: #999999;line-height: 26px;
				}
			}

			.fllow{
				>div{
					// >div:first-child>a{
					// 	display: inline-block;
					// }
					// >div:first-child>a:hover{
					// 	>span::after{
					// 		opacity: 0.4;
					// 	}
					// }
					>div:first-child>a>span{
						display: inline-block;
						width: 40px;
						height: 40px;
						position: relative;
						// >span{
						// 	width: 100%;
						// 	height: 100%;
						// 	display: inline-block;
						// 	position: relative;

							> button{
								width: 100%;
								overflow: hidden;
								position: absolute;
								top: 0;
								left: 0;
								border-radius: 50%;
								z-index: 1000;
								width: 100%;
								height: 100%;
								opacity: 0;
							}
						// }
					}
				}
				
				>div:nth-child(1)>div:first-child>a>span{background: url("../../../assets/pc/follow2.png") no-repeat 0 0;}
				>div:nth-child(2)>div:first-child>a>span{background: url("../../../assets/pc/follow2.png") no-repeat -57px 0;}
				>div:nth-child(3)>div:first-child>a>span{background: url("../../../assets/pc/follow2.png") no-repeat -114px 0;}
				>div:nth-child(4)>div:first-child>a>span{background: url("../../../assets/pc/follow2.png") no-repeat -170px 0;}
				>div:nth-child(5)>div:first-child>a>span{background: url("../../../assets/pc/follow2.png") no-repeat -227px 0;}

				>div>div:first-child>a>span::after{
					background: #e1e1e1;
    				opacity: 0.0;
					border-radius: 50%;
					display: inline-block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
    				content: "";
				}

				>div>div:first-child>a{
					display: inline-block;
				}
				>div>div:first-child>a:hover{
					>span::after{
						opacity: 0.4;
					}
				}

				
			}
		}
	}
</style>
<template>
  <div class="footer">
    <div class="con page_center">
		<div class="about fs12 color-6 fl">
			<div class="fs14 mt40 color-9">
				
				<router-link style="color:#999" to="/about/about-us" target='_blank'>关于我们</router-link> |
				
				<router-link style="color:#999" to="/about/contact-us" target='_blank'>联系我们</router-link> |
				
				<router-link style="color:#999" to="/about/job-guide" target='_blank'>求职指引</router-link> |
				
				<router-link style="color:#999" to="/about/business-guide" target='_blank'>业务指引</router-link> |
				
				<router-link style="color:#999" to="/about/server-protocol" target='_blank'>服务协议</router-link> 
				
				
			</div>

			<div class="pt10 fs12">
				<span>Copyright © 2015 hrllzp.com - All Rights Reserved</span>
			</div>
			<div class="fs12">
				<a style="color:#666" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备15061579号-1</a>
			</div>
		</div>
		<div class="fr color-9 fs12">
			<div class="fllow dflex tac mt30 color-6">
				<div>
					<div>
						<a href="javascript:" target="_self" title="">
							<span class="fllow2 curpo">
								<el-popover
									placement="top-start"
									title="邮箱地址："
									trigger="click"
									content="llz@hrllzp.com">
									<template #reference><el-button ></el-button></template>
								</el-popover>
							</span>
						</a>
					</div>
					<div>邮箱</div>
				</div>
				<div>
					<div>
						<a href="javascript:" target="_self" title="">
							<span class="fllow2 curpo">
								<el-popover
									placement="top-start"
									title="联系电话："
									trigger="click"
									content="020 - 28932643">
									<template #reference><el-button></el-button></template>
								</el-popover>
							</span>
						</a>
					</div>
					<div>热线</div>
				</div>
				<div>
					<div>
						<a href="tencent://message/?uin=1458267119&Site=&Menu=yes" title="QQ Profile"><span class="fllow2 curpo"></span></a>
					</div>
					<div>QQ</div>
				</div>
				<div>
					<div>
						<a href="javascript:;" @click="centerDialogVisible = true" title="Weixin Profile"><span class="fllow2 curpo"></span></a>
					</div>
					<div>微信</div>
				</div>
				<div>
					<div>
						<a href="https://weibo.com/u/2092783853?is_all=1" target="_blank" title="Weibo Profile"><span class="fllow2 curpo"></span></a>
					</div>
					<div>微博</div>
				</div>



			</div>
			<div class="fs12 color-9 mt10">地址：<span class="dib"></span>广州市黄埔区黄埔东路5号东城国际公寓15层1506</div>
		</div>

		<el-dialog title="关注公众号" v-model="centerDialogVisible" width="250px" center>
			<div style="text-align: center;" class="">
			<img src="../../../assets/pc/weixin2.png" width="200" height="200" /></div>
			<span class="dialog-footer">
			</span>
		</el-dialog>
		
		

	</div>
  </div>
</template>
<script>
export default( {
		data() {
      		return {
        		centerDialogVisible: false
			};
		},
		methods: {
		
		}
	})
</script>
