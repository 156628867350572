import cookie from 'js-cookie';
import server from './index';
import { ElMessage} from 'element-plus';
// import post_form from 'modules/post_form';

const reloginCode = ['1002'];
const serverName = process.env.REQUEST_URL;
const publicServerName = process.env.PUBLIC_URL;
// let passport_serverName = process.env.PASSPORT_URL;

function logout(code) {
    cookie.remove('token');
    cookie.remove('token');
    cookie.remove('user_id');
    // window.location.href = '/login'
    //// //console.log(window.location.href);
    //window.location.href = '/';//passport_serverName;//;
    //post_form('login');
}

function handleResponse(res, requestPath) {
    return new Promise(function (resolve, reject) {
        if(res.ok) {
            resolve(res.json());
        } else {
            reject(res.statusText);
        }
    }).then(function (data) {
        if(data.error_code === '0'){
            if(Object.prototype.hasOwnProperty.call(data, "total_rows")){
                data.result['total_rows'] = data['total_rows'];
            }
            if(Object.prototype.hasOwnProperty.call(data, "total_rows_all")){
                data.result['total_rows_all'] = data['total_rows_all'];
            }
            if(Object.prototype.hasOwnProperty.call(data, "no_read")){
                data.result['no_read'] = data['no_read'];
            }
            return data.result;
        } else if(reloginCode.includes(data.error_code)){
            logout(data.error_code);
        } else {
            return Promise.reject(data.reason || 'no msg error');
        }
    })
}

export function getPublic(url, params = {}, config = {error: true, public: true}) {
    return server.get(url, params, config);
}

export function get(url, params = {}, config = {error: true}) {
    let loadingTimer;
    const requestServer = config.public ? publicServerName : serverName;
    function finish() {
        if(config.loading){
            clearTimeout(loadingTimer);
            // notice.hide_loading();
        }
    }


    if(config.loading){
        // loadingTimer = setTimeout(function () {
        //     notice.show_loading();
        // }, 500);
    }


    params.token = params.token || cookie.get('token');
    // return server.get(requestServer + url, params)
    return server.get(url, params)
        .then(function (res) {
            return handleResponse(res, url);
        })
        .then(function (data) {
            finish();
            return data;
        })
        .catch(function (msg) {
            const error = config.error;
            ElMessage({type:'error',grouping:true,message:msg});
            finish();
            return Promise.reject(msg);
        });
};

export function firstGet(url, params = {}, config = {error: true}) {
    let loadingTimer;
    const requestServer = config.public ? publicServerName : serverName;
    function finish() {
        if(config.loading){
            clearTimeout(loadingTimer);
            // notice.hide_loading();
        }
    }


    if(config.loading){
        // loadingTimer = setTimeout(function () {
        //     notice.show_loading();
        // }, 500);
    }


    params.token = params.token || cookie.get('token');
    // return server.get(requestServer + url, params)
    return server.firstGet(url, params)
        .then(function (res) {
            return handleResponse(res, url);
        })
        .then(function (data) {
            finish();
            return data;
        })
        .catch(function (msg) {
            const error = config.error;
            ElMessage({type:'error',grouping:true,message:msg});
            finish();
            return Promise.reject(msg);
        });
};



export function postPublic(url, params = {}, config = {error: true, public: true}) {
    return server.post(url, params, config);
}

export function post(url, params = {}, config = {error: true}) {

    let loadingTimer;
    function finish() {
        if(config.loading){
            clearTimeout(loadingTimer);
            // notice.hide_loading();
        }
    }


    if(config.loading){
        // loadingTimer = setTimeout(function () {
        //     notice.show_loading();
        // }, 500);
        
    }

    params.token = params.token || cookie.get('token');
    // window.//console.log("url: "+url);
    // window.//console.log("params: "+JSON.stringify(params));
    let thes = this
    return server.post( url, params)
        .then(function (res) {
            return handleResponse(res, url);
        })
        .then(function (data) {
            finish();
            return data;
        })
        .catch(function (msg) {
            const error = config.error;
            // error && ElMessage({type:'error',grouping:true,message:
            //     typeof error === 'string' ? error : msg });
            ElMessage({type:'error',grouping:true,message:msg});
            finish();
            return Promise.reject(msg);
        })
}

export function form(url, params = {}, config = {error: true}){
    const requestServer = config.public ? publicServerName : serverName;
    params.token = params.token || cookie.get('token');
    return server.form( url, params)
        .then(function (res) {
            return handleResponse(res, url);
        })
        .catch(function (msg) {
            const error = config.error;
            ElMessage({type:'error',grouping:true,message:msg});
            return Promise.reject(msg);
        });
}

export function formData(url, formData, config = {error: true}){
    const requestServer = config.public ? publicServerName : serverName;
    formData.append('token',cookie.get('token'));
    return server.formData( url, formData)
        .then(function (res) {
            return handleResponse(res, url);
        })
        .catch(function (msg) {
            const error = config.error;
            ElMessage({type:'error',grouping:true,message:msg});
            return Promise.reject(msg);
        });
}

export default {
    get,
	firstGet,
    post,
    form,
    formData,
    getPublic,
    postPublic,
    logout,
}

