import constants from '../constants';

const state = {
    list: []

};

const mutations = {
    [constants.SET_PROVINCE] (state, list) {
        state.list = list;

    }
};

export default {
    state,
    mutations,
};
