import { createApp } from 'vue'
import App from '../App.vue'
import Vuex from 'vuex';
// import { createPersistedState, createSharedMutations } from 'vuex-electron'
// import createLogger from 'vuex/dist/logger';
import user from './modules/user';
import industry from './modules/industry';
import province from './modules/province';
import city from './modules/city';
import topbar from './modules/topbar';


// var createLogger = Vuex.createLogger() ;

const app = createApp(App)
app.use(Vuex);

// const plugins = process.env.NODE_ENV === 'development' ?
//     [createLogger()] : [];

export default new Vuex.Store({
  modules: {
    user, //引用方法 store.state.user
    industry,//store.state.industry.industry_list
    province,//store.state.province.province_list
    city,
    topbar
  },
//   plugins
})

// if (module.hot) {
//   module.hot.accept(['./actions'], () => {
//     const newActions = require('./actions').default;
//     store.hotUpdate({
//       actions: newActions
//     })
//   })
// }
