import cookie from 'js-cookie';
import server from '../server/resource';
// import post_form from 'modules/post_form'
// import {ui_config} from 'config/api';

export function wexin_login(code,role) {
    return server.post('wexin_login', {code,role});
}

export function switch_role(role) {
    return server.post('switch_role', {role});
}

export function get_my_info() {
    return server.get('get_my_info', {});
}

export function getMyAdvisor() {
    return server.get('getMyAdvisor', {});
}

export function get_user(id) {
    return server.post('get_user', {id});
}

export function get_user_company(id) {
    return server.post('get_user_company', {id});
}

export function get_user_company_ad_list() {
    return server.get('get_user_company_ad_list', {});
}

export function get_user_hr(id) {
    return server.post('get_user_hr', {id});
}

export function get_my_company() {
    return server.post('get_my_company', {});
}

export function get_my_hr() {
    return server.post('get_my_hr', {});
}

export function update_user_company(item) {
    return server.post('update_user_company', item);
}

export function updateAdvisorInfo(advisor) {
    return server.post('updateAdvisorInfo', advisor);
}
export function updateUserInfo(user) {
    return server.post('updateUserInfo', user);
}

export function update_user_hr(item) {
    return server.post('update_user_hr', item);
}


export function get_my_message(page = 1, rows = 999) {
    return server.post('get_my_message', {page, rows});
}

export function get_my_point() {
    return server.post('get_my_point', {});
}

export function get_my_point_record(page = 1, rows = 999) {
    return server.post('get_my_point_record', {page, rows});
}

export function get_my_money() {
    return server.post('get_my_money', {});
}

export function get_my_money_record(page = 1, rows = 999) {
    return server.post('get_my_money_record', {page, rows});
}

export function transport_money(email, value,role) {
    return server.post('transport_money', {email,value,role});
}

export function transport_point(email, value,role) {
    return server.post('transport_point', {email,value,role});
}

export function get_my_lib() {
    return server.post('get_my_lib', {});
}

export function expand_my_lib(lib_type) {
    return server.post('expand_my_lib', {lib_type});
}

export function search_user_hr(keyword, page = 1, rows = 999) {
    return server.post('search_user_hr', {keyword, page, rows});
}

export function set_fapiao(bean) {
    return server.post('set_fapiao', bean);
}

export function get_my_fapiao() {
    return server.post('get_my_fapiao', {});
}

export function kai_fapiao(money) {
    return server.post('kai_fapiao', {money});
}

