import constants from '../constants';

const state = {
    id: '',
    nickname: '',
    gender: 0,
    phone: '',
    email: '',
    avatar: '',
    role: 0,
    is_check: 0,
    type: 0,
};

const mutations = {
    [constants.SET_USER] (state, user) {
        // // //console.log("state: "+JSON.stringify(state))
        // // //console.log("user: "+JSON.stringify(user))
        if(user !== undefined && user !== null){
            state.id = user.id;
            state.gender = user.gender;
            state.phone = user.phone;
            state.email = user.email;
            state.avatar = user.avatar;
            state.role = user.role;
            state.type = user.type;
            state.nickname = user.nickname;
            state.is_check = user.is_check;
        }else{
            state.id = "";
            state.gender = 0;
            state.phone = "";
            state.email = "";
            state.avatar = "";
            state.role = 0;
            state.type = 0;
            state.nickname = "";
            state.is_check = 0;
        }

    }
};

// const actions = {
//     [constants.SET_USER]({dispatch},user){
//         // //console.log("insert: "+JSON.stringify(user))
//         dispatch(constants.SET_USER,user)
//     }
// }


export default {
    state,
    mutations,
    // actions
};
