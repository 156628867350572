import constants from '../constants';

const state = {
    person: 0,
    personCenter: 0,
};

const mutations = {
    [constants.TOPBAR] (state, data) {


        if(data !== undefined && data !== null){
            // //console.log("data: "+JSON.stringify(data))
            state.person = data.person;
            state.personCenter = data.personCenter;
            // //console.log("state: "+JSON.stringify(state))
        }else{
            state.person = 0;
            state.personCenter = 0;
        }

    }
};

// const actions = {
//     [constants.SET_USER]({dispatch},user){
//         // //console.log("insert: "+JSON.stringify(user))
//         dispatch(constants.SET_USER,user)
//     }
// }


export default {
    state,
    mutations,
    // actions
};
