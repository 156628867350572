import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  // ------- PC端
    // 首页
    {
        path: "/",
        name: "Index",
        component: () => import("../views/Index.vue")
    },
    {
        path: "/myTest",
        name: "MyTest",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue")
    },
    //登陆和注册-pc
    {
        path: "/logon/:logonRole",
        name: "Logon",
        component: () => import("../views/desktop/logon.vue")
    },
    {
        path: "/jobdetail/:id",
        name: "Jobdetail",
        component: () => import("../views/desktop/public/jobdetail.vue")
    },
    {
        path: "/smart-helper-add/:id",
        name: "Smart-helper-add",
        component: () => import("../views/desktop/public/smart-helper-add.vue")
    },
    {
        path: "/helper-search-resume/:id",
        name: "Helper-search-resume",
        component: () => import("../views/desktop/public/helper-search-resume.vue")
    },
    {
        path: '/hunter-job-edit/:id',
        name: 'Hunter-job-edit',
        component: () => import("../views/desktop/sharezp/hunter-job-edit.vue")
    },
    {
        path: '/publish-job-edit/:id',
        name: 'Publish-job-edit',
        component: () => import("../views/desktop/sharezp/publish-job-edit.vue")
    },
    {
        path: '/open-resume/:id',
        name: 'Open-resume',
        component: () => import("../views/desktop/public/open-resume.vue")
    },
    {
        path: '/watch-resume/:id',
        name: 'Watch-resume',
        component: () => import("../views/desktop/public/watch-resume.vue")
    },
    {
        path: '/hr-recommend/:id',
        name: 'Hr-recommend',
        component: () => import("../views/desktop/sharezp/hrhunter-recommend.vue")
    },
    {
        path: "/completeRegister",
        name: "CompleteRegister",
        component: () => import("../views/desktop/completeRegister.vue")
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/desktop/register.vue")
    },
    {
        path: "/resetPasswords",
        name: "ResetPasswords",
        component: () => import("../views/desktop/resetPasswords.vue")
    },
    {
        path: "/hrhunter-community-detail/:id",
        name: "Hrhunter-community-detail",
        component: () => import("../views/desktop/hrhunter/community-detail.vue")
    },
    {
        path: "/hrhunter-investigation-detail/:id",
        name: "Hrhunter-investigation-detail",
        component: () => import("../views/desktop/hrhunter/investigation-detail.vue")
    },

    // 求职者-pc
    {
        path: "/personjob",
        name: "Personjob",
        component: () => import("../views/desktop/personjob/index.vue"),
        children: [
            {
                path: 'job',
                name: 'Job',
                component: () => import("../views/desktop/personjob/job.vue")
            },
            {
                path: 'resume',
                name: 'Resume',
                component: () => import("../views/desktop/personjob/resume.vue")
            },
            {
                path: 'send-resume',
                name: 'Send-resume',
                component: () => import("../views/desktop/personjob/send-resume.vue")
            },
            {
                path: 'company-invite',
                name: 'Company-invite',
                component: () => import("../views/desktop/personjob/company-invite.vue")
            },
            {
                path: 'hr-entrust',
                name: 'Hr-entrust',
                component: () => import("../views/desktop/personjob/hr-entrust.vue")
            },
            {
                path: 'my-point',
                name: 'My-point',
                component: () => import("../views/desktop/public/my-point.vue")
            },
            {
                path: 'my-wealth',
                name: 'My-wealth',
                component: () => import("../views/desktop/public/my-wealth.vue")
            },
            {
                path: 'my-message',
                name: 'My-message',
                component: () => import("../views/desktop/public/my-message.vue")
            }

        ]
    },


    // 求职者-个人中心-pc
    // {
    //   path: "/person-center",
    //   name: "Person-center",
    //   component: () => import("../views/desktop/personjob/center-index.vue"),
    //   children: [
    //     {
    //       path: 'my-point',
    //       name: 'My-point',
    //       component: () => import("../views/desktop/personjob/my-point.vue")
    //     },
    //     {
    //       path: 'my-wealth',
    //       name: 'My-wealth',
    //       component: () => import("../views/desktop/personjob/my-wealth.vue")
    //     },
    //     {
    //       path: 'my-message',
    //       name: 'My-message',
    //       component: () => import("../views/desktop/personjob/my-message.vue")
    //     }
    //
    //   ]
    // }


    // 企业-pc
    {
        path: "/sharezp",
        name: "Sharezp",
        component: () => import("../views/desktop/sharezp/index.vue"),
        children: [
            {
                path: 'hunter-job',
                name: 'Hunter-job',
                component: () => import("../views/desktop/sharezp/hunter-job.vue")
            },
            {
                path: 'publish-job',
                name: 'Publish-job',
                component: () => import("../views/desktop/sharezp/publish-job.vue")
            },
            {
                path: 'manage-job',
                name: 'Manage-job',
                component: () => import("../views/desktop/sharezp/manage-job.vue")
            },
            {
                path: 'apply-resume',
                name: 'Apply-resume',
                component: () => import("../views/desktop/sharezp/apply-resume.vue")
            },
            {
                path: 'hr-recommend-list',
                name: 'Hr-recommend-list',
                component: () => import("../views/desktop/sharezp/hr-recommend-list.vue")
            },
            {
                path: 'search',
                name: 'Search-resume-sharezp',
                component: () => import("../views/desktop/public/search-resume.vue")
            },
            {
                path: 'person-lib',
                name: 'Person-lib-sharezp',
                component: () => import("../views/desktop/public/person-lib.vue")
            },
            {
                path: 'person-import',
                name: 'Person-import-sharezp',
                component: () => import("../views/desktop/public/person-import.vue")
            },
            {
                path: 'company-introduction-edit',
                name: 'Company-introduction-edit',
                component: () => import("../views/desktop/sharezp/company-introduction-edit.vue")
            },
            {
                path: 'company-introduction',
                name: 'Company-introduction',
                component: () => import("../views/desktop/sharezp/company-introduction.vue")
            },
            {
                path: 'my-wealth',
                name: 'My-wealth-sharezp',
                component: () => import("../views/desktop/public/my-wealth.vue")
            },
            {
                path: 'my-point',
                name: 'My-point-sharezp',
                component: () => import("../views/desktop/public/my-point.vue")
            },
            {
                path: 'my-message',
                name: 'My-message-sharezp',
                component: () => import("../views/desktop/public/my-message.vue")
            },
            {
                path: 'invoice',
                name: 'Invoice',
                component: () => import("../views/desktop/sharezp/invoice.vue")
            }

        ]
    }
    ,
    // Hr猎人-pc
    {
        path: "/hrhunter",
        name: "Hrhunter",
        component: () => import("../views/desktop/hrhunter/index.vue"),
        children: [
            {
                path: 'money-job',
                name: 'Money-job',
                component: () => import("../views/desktop/hrhunter/money-job.vue")
            },
            {
                path: 'my-recommend',
                name: 'My-recommend',
                component: () => import("../views/desktop/hrhunter/my-recommend.vue")
            },
            {
                path: 'person-lib',
                name: 'Person-lib',
                component: () => import("../views/desktop/public/person-lib.vue")
            },
            {
                path: 'person-import',
                name: 'Person-import',
                component: () => import("../views/desktop/public/person-import.vue")
            },
            {
                path: 'search',
                name: 'Search',
                component: () => import("../views/desktop/public/search-resume.vue")
            },
            {
                path: 'download-resume',
                name: 'Download-resume',
                component: () => import("../views/desktop/hrhunter/download-resume.vue")
            },
            {
                path: 'entrust',
                name: 'Entrust',
                component: () => import("../views/desktop/hrhunter/entrust.vue")
            },
            {
                path: 'helper-list',
                name: 'Helper-list',
                component: () => import("../views/desktop/hrhunter/helper-list.vue")
            },
            {
                path: 'hr-community',
                name: 'Hr-community',
                component: () => import("../views/desktop/hrhunter/hr-community.vue")
            },
            {
                path: 'hr-investigation',
                name: 'Hr-investigation',
                component: () => import("../views/desktop/hrhunter/hr-investigation.vue")
            },
            {
                path: 'account-setting',
                name: 'Account-setting',
                component: () => import("../views/desktop/hrhunter/account-setting.vue")
            },
            {
                path: 'my-wealth',
                name: 'My-wealth-hrhunter',
                component: () => import("../views/desktop/public/my-wealth.vue")
            },
            {
                path: 'my-point',
                name: 'My-point-hrhunter',
                component: () => import("../views/desktop/public/my-point.vue")
            },
            {
                path: 'my-message',
                name: 'My-message-hrhunter',
                component: () => import("../views/desktop/public/my-message.vue")
            },
        ]
    }









    ,
    // 求职助手
    {
        path: "/job_helper",
        name: "Job_helper",
        component: () => import("../views/desktop/job_helper/main.vue"),
        children: [
            {
                path: 'about',
                name: 'About',
                component: () => import("../views/desktop/job_helper/components/about.vue")
            },
        ]
    }
    ,
    //面试问道
    {
        path: '/question_content/:id',
        name: 'Question_content',
        component: () => import("../views/desktop/interview/question/content.vue")
    },
	{
        path: '/question_common/:type',
        name: 'Question_common',
        component: () => import("../views/desktop/interview/question/common.vue")
    },
	{
        path: '/question_mo',
        name: 'QuestionM',
        component: () => import("../views/mobile/interview/questionM.vue")
    },
	{
        path: '/question_mo_con',
        name: 'QuestionM_con',
        component: () => import("../views/mobile/interview/questionM_content.vue")
    },
    {
        path: "/interview",
        name: "Interview",
        component: () => import("../views/desktop/interview/index.vue"),
        children: [
            //首页
            {
                path: 'mainPage',
                name: 'MainPage',
                component: () => import("../views/desktop/interview/main.vue"),
            },

            //面试题解
            {
                path: 'question',
                name: 'Question',
                component: () => import("../views/desktop/interview/question/index.vue"),
            },
            
            {
                path: 'general_question',
                name: 'General_question',
                component: () => import("../views/desktop/interview/question/general.vue")
            },
            {
                path: 'special_question',
                name: 'Special_question',
                component: () => import("../views/desktop/interview/question/special.vue")
            },
            {
                path: 'top_question',
                name: 'Top_question',
                component: () => import("../views/desktop/interview/question/top.vue")
            },
            //测试栏目
            {
                path: 'resume_test',
                name: 'Resume_test',
                component: () => import("../views/desktop/interview/resume_test/resume.vue"),
            },
            {
                path: 'phone_test',
                name: 'Phone_test',
                component: () => import("../views/desktop/interview/phone_test/index.vue"),
            },
            {
                path: 'career_quiz',
                name: 'Career_quiz',
                component: () => import("../views/desktop/interview/career_quiz/index.vue"),
            },
            {
                path: 'nine_personality_test',
                name: 'Nine_personality_test',
                component: () => import("../views/desktop/interview/nine_personality_test/index.vue"),
            },
            
        ]
    },
	{
		path: '/testM',
		name: 'TestM',
		component: () => import("../views/desktop/interview/testMIndex.vue"),
		children:[
			{
                path: 'home',
                name: 'testM_home',
                component: () => import("../views/desktop/interview/testM_home.vue"),
            },
			{
                path: 'phone_test',
                name: 'testM_phone_test',
                component: () => import("../views/desktop/interview/phone_test/phone_testM.vue"),
            },
			{
                path: 'phone_test-st',
                name: 'testM_phone_test-st',
                component: () => import("../views/desktop/interview/phone_test/phone_testM_st.vue"),
            },
			{
                path: 'interviews',
                name: 'testM_interviews',
                component: () => import("../views/desktop/interview/question/home.vue"),
            },
		]
	},
	//职导一对一
	{
		path: '/advisor',
		name: 'Advisor',
		component: () => import("../views/desktop/advisor/index.vue"),
		children: [
			{
                path: 'main',
                name: 'Main',
                component: () => import("../views/desktop/advisor/main.vue"),
            },
			{
                path: 'products',
                name: 'Products',
                component: () => import("../views/desktop/advisor/products.vue"),
            },
			{
                path: 'mine',
                name: 'Mine',
                component: () => import("../views/desktop/advisor/mine.vue"),
            },
			{
                path: 'center',
                name: 'Center',
                component: () => import("../views/desktop/advisor/center.vue"),
            },
			
		]
	},
	//一对一手机版页面
	{
		path: '/advisorM',
		name: 'advisorM',
		component: () => import("../views/mobile/advisor/advisorIndex.vue"),
		children: [
			//
			{
                path: 'home',
                name: 'advisorM_home',
                component: () => import("../views/mobile/advisor/home/home.vue"),
            },
			{
                path: 'hometeac',
                name: 'advisorM_home_teac',
                component: () => import("../views/mobile/advisor/home/homeTeac.vue"),
            },
			{
                path: 'question_list',
                name: 'advisorM_question_list',
                component: () => import("../views/mobile/advisor/public/questionList.vue"),
            },

			//search
			{
                path: 'search',
                name: 'advisorM_search',
                component: () => import("../views/mobile/advisor/search/search.vue"),
            },
			{
                path: 'searchteac',
                name: 'advisorM_search_teac',
                component: () => import("../views/mobile/advisor/search/searchTeac.vue"),
            },

			//chat
			{
                path: 'chat',
                name: 'advisorM_chat',
                component: () => import("../views/mobile/advisor/chat/chat.vue"),
            },
			{
                path: 'chatMessage',
                name: 'advisorM_chatMessage',
                component: () => import("../views/mobile/advisor/chat/chatMessage.vue"),
            },
			{
                path: 'chatteac',
                name: 'advisorM_chat_teac',
                component: () => import("../views/mobile/advisor/chat/chatTeac.vue"),
            },
			{
                path: 'chatMessageteac',
                name: 'advisorM_chatMessage_teac',
                component: () => import("../views/mobile/advisor/chat/chatMessageTeac.vue"),
            },

			//user
			{
                path: 'user',
                name: 'advisorM_user',
                component: () => import("../views/mobile/advisor/user/user.vue"),
            },
			{
                path: 'userteac',
                name: 'advisorM_user_teac',
                component: () => import("../views/mobile/advisor/user/userTeac.vue"),
            },
			{
                path: 'shopCart',
                name: 'advisorM_shopCart',
                component: () => import("../views/mobile/advisor/user/shopCart.vue"),
            },
			{
                path: 'myInfo',
                name: 'advisorM_myInfo',
                component: () => import("../views/mobile/advisor/user/myInfo.vue"),
            },
			{
                path: 'myInfoteac',
                name: 'advisorM_myInfoteac',
                component: () => import("../views/mobile/advisor/user/myInfoteac.vue"),
            },
			{
                path: 'orderInfo',
                name: 'advisorM_orderInfo',
                component: () => import("../views/mobile/advisor/user/orderInfo.vue"),
            },
			{
                path: 'accountSecurity',
                name: 'advisorM_accountSecurity',
                component: () => import("../views/mobile/advisor/user/accountSecurity.vue"),
            },
			{
                path: 'setting',
                name: 'advisorM_setting',
                component: () => import("../views/mobile/advisor/user/setting.vue"),
            },
			{
                path: 'aboutUs',
                name: 'advisorM_aboutUs',
                component: () => import("../views/mobile/advisor/user/aboutUs.vue"),
            },
			{
                path: 'feedback',
                name: 'advisorM_feedback',
                component: () => import("../views/mobile/advisor/user/feedback.vue"),
            },
			{
                path: 'login',
                name: 'advisorM_login',
                component: () => import("../views/mobile/advisor/user/login.vue"),
            },
			{
                path: 'register',
                name: 'advisorM_register',
                component: () => import("../views/mobile/advisor/user/register.vue"),
            },


		]
	},


    // 关于
    {
        path: "/about",
        name: "About",
        component: () => import("../views/desktop/about/main.vue"),
        children: [
            {
                path: 'about-us',
                name: 'About-us',
                component: () => import("../views/desktop/about/components/about-us.vue")
            },
            {
                path: 'business-guide',
                name: 'Business-guide',
                component: () => import("../views/desktop/about/components/business-guide.vue")
            },
            {
                path: 'contact-us',
                name: 'Contact-us',
                component: () => import("../views/desktop/about/components/contact-us.vue")
            },
			{
                path: 'hr-community',
                name: 'About-hr-community',
                component: () => import("../views/desktop/about/components/hr-community.vue")
            },
            {
                path: 'enterprise-pact',
                name: 'Enterprise-pact',
                component: () => import("../views/desktop/about/components/enterprise.vue")
                            

            },
            {
                path: 'hunter-pact',
                name: 'Hunter-pact',
                component: () => import("../views/desktop/about/components/HR-hunter.vue")
            },
            {
                path: 'job-guide',
                name: 'Job-guide',
                component: () => import("../views/desktop/about/components/job-guide.vue")
            },
            {
                path: 'server-protocol',
                name: 'Server-protocol',
                component: () => import("../views/desktop/about/components/server-protocol.vue")
            },
			{
				path: 'mainTest',
                name: 'MainTest',
                component: () => import("../views/desktop/interview/mainTest.vue"),
            },
        ]
    }
    ,
    // 牛人众帮-pc
    {
        path: "/otherTest",
        name: "OtherTest",
        component: () => import("../views/desktop/bullshare/index.vue"),
        children: [
            {
                path: 'index',
                component: () => import("../views/desktop/bullshare/index.vue")
            },
        ]
    },
	//src\views\desktop\test\word_library_input.vue
	{
        path: "/testN",
        name: "TestN",
        component: () => import("../views/desktop/test/index.vue"),
        children: [
            {
                path: 'word_input',
				name: 'Word_input',
                component: () => import("../views/desktop/test/word_library_input.vue")
            },
			{
                path: 'name_personality',
				name: 'Name_personality',
                component: () => import("../views/desktop/test/name_personality.vue")
            },
			{
                path: 'doushu',
				name: 'Doushu',
                component: () => import("../views/desktop/test/doushu.vue")
            },
			{
                path: 'xiaoliu',
				name: 'Xiaoliu',
                component: () => import("../views/desktop/test/xiaoliu.vue")
            },
			{
                path: 'yinyuan',
				name: 'Yinyuan',
                component: () => import("../views/desktop/test/yinyuange.vue")
            },
        ]
    }
]

const router = createRouter({
  	history: createWebHistory(),
  	// history: createWebHashHistory(),
  	routes
})

export default router
