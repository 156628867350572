import constants from '../constants';
import {get_my_info} from '../../api/user';
import {get_industry_list, get_province_list, get_city_list} from '../../api/public';
import cookie from 'js-cookie';
import store from "../index";
// import action from 'vuex';
// import dispatch from 'vuex';
import Vuex from 'vuex';
import key_mirror from "key-mirror";
import user from "../modules/user";
let self = this;

// export function set_user() {
//
//     return get_my_info({dispatch})
//         .then(function (data) {
//             // //console.log("get_my_info:")
//             // //console.log(data)
//             if(data !== undefined) {
//                 cookie.set('user_id', data.id);
//                 // dispatch(constants.SET_USER, data);
//                 // this.$store.dispatch(constants.SET_USER, data);
//                // dispatch(constants.SET_USER);
//                 dispatch(constants.SET_USER, data);
//
//
//                 // action(constants.SET_USER,data);
//             }
//             return data;
//         });
// }


export function set_user() {
    return get_my_info()
        .then(function (data) {
            if(data != undefined) {
                cookie.set('user_id', data.id);
                store.commit(constants.SET_USER, data);
            }
            return data;
        });
}


export function set_industry() {
    return  get_industry_list()
        .then(function (data) {
            store.commit(constants.SET_INDUSTRY, data);
        });
}

export function set_province() {
    return get_province_list()
        .then(function (data) {
            store.commit(constants.SET_PROVINCE, data);
        });
}

// export function set_city({dispatch}) {
export function set_city() {
    return  get_city_list(null)
        .then(function (data) {
            store.commit(constants.SET_CITY, data);
        });
}

